import { PartnerJsonApiEntity } from '@one-vision/json-api-parser';
import { statesList } from 'constants/states';
import { timeZones } from 'constants/timeZones';

export const timeZonesList = timeZones.map((el) => ({
  value: el,
  text: el,
}));

export enum InputType {
  input = 'input',
  autocomplete = 'autocomplete',
  datePicker = 'datePicker',
  toggle = 'toggle',
  phone = 'phone',
  email = 'email',
}

export interface ListItem {
  name: keyof PartnerJsonApiEntity['attributes'];
  label: string;
  inputType: InputType;
  options?: { value: string; text: string }[];
}

export const fieldsList: ListItem[] = [
  {
    name: 'currentPartner',
    label: 'Current partner',
    inputType: InputType.toggle,
  },
  {
    name: 'businessName',
    label: 'Business Name',
    inputType: InputType.input,
  },
  {
    name: 'revenueShare',
    label: 'Revenue Share',
    inputType: InputType.input,
  },
  {
    name: 'address',
    label: 'Address',
    inputType: InputType.input,
  },
  {
    name: 'city',
    label: 'City',
    inputType: InputType.input,
  },
  {
    name: 'state',
    label: 'State',
    inputType: InputType.autocomplete,
    options: statesList,
  },
  {
    name: 'zip',
    label: 'Zip',
    inputType: InputType.input,
  },
  {
    name: 'timeZone',
    label: 'Time zone',
    inputType: InputType.autocomplete,
    options: timeZonesList,
  },
  {
    name: 'contractSignedDate',
    label: 'Contract Signed',
    inputType: InputType.datePicker,
  },
  {
    name: 'supportLiveDate',
    label: 'Support Live',
    inputType: InputType.datePicker,
  },
  {
    name: 'marketingLaunchDate',
    label: 'Marketing Live',
    inputType: InputType.datePicker,
  },
  {
    name: 'litsDate',
    label: 'LitS',
    inputType: InputType.datePicker,
  },
  {
    name: 'supportPhone',
    label: 'Support Phone',
    inputType: InputType.input,
  },
  {
    name: 'supportEmail',
    label: 'Support Email',
    inputType: InputType.email,
  },
  {
    name: 'signaturePhone',
    label: 'Signature Phone',
    inputType: InputType.input,
  },
  {
    name: 'signatureEmail',
    label: 'Signature Email',
    inputType: InputType.email,
  },
  {
    name: 'membershipEmail',
    label: 'Membership Email',
    inputType: InputType.email,
  },
  {
    name: 'membershipSalesCalendlyLink',
    label: 'MS Calendly URL',
    inputType: InputType.input,
  },
  {
    name: 'membershipUrlNew',
    label: 'Membership URL Res',
    inputType: InputType.input,
  },
  {
    name: 'membershipUrlComNew',
    label: 'Membership URL Com',
    inputType: InputType.input,
  },
  {
    name: 'websiteUrl',
    label: 'Website URL',
    inputType: InputType.input,
  },
  {
    name: 'zendeskUrl',
    label: 'Zendesk URL',
    inputType: InputType.input,
  },
  {
    name: 'zendeskDomain',
    label: 'Zendesk Domain',
    inputType: InputType.input,
  },
  {
    name: 'slackTeamLink',
    label: 'Slack Team URL',
    inputType: InputType.input,
  },
  {
    name: 'billingEmail',
    label: 'Billing Email',
    inputType: InputType.email,
  },
  {
    name: 'billingEmail2',
    label: 'Billing Email 2',
    inputType: InputType.email,
  },
  {
    name: 'buyNow',
    label: 'Buy now',
    inputType: InputType.toggle,
  },
  {
    name: 'tos',
    label: 'TOS URL',
    inputType: InputType.input,
  },
  {
    name: 'annual',
    label: 'Annual',
    inputType: InputType.toggle,
  },
  {
    name: 'notionBoardUrl',
    label: 'Marketing Review URL',
    inputType: InputType.input,
  },
  {
    name: 'docsendUrl',
    label: 'Onboarding Resources URL',
    inputType: InputType.input,
  },
  {
    name: 'stripeAccountId',
    label: 'Stripe Account ID',
    inputType: InputType.input,
  },
  {
    name: 'stripeCustomerId',
    label: 'Stripe Customer ID',
    inputType: InputType.input,
  },
  {
    name: 'iterableTestUserId',
    label: 'Iterable Test User ID',
    inputType: InputType.input,
  },
  {
    name: 'globalNotificationEmail',
    label: 'Global Notification Email',
    inputType: InputType.email,
  },
];
